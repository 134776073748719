import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
window.fabric.Sprite = window.fabric.util.createClass(window.fabric.Image, {

  type: 'sprite',

  spriteWidth: 200,
  spriteHeight: 250,
  spriteIndex: 0,
  frameTime: 100,

  initialize: function(element, options) {
    options || (options = { });

    options.width = this.spriteWidth;
    options.height = this.spriteHeight;

    this.callSuper('initialize', element, options);

    this.createTmpCanvas();
    this.createSpriteImages();
  },

  createTmpCanvas: function() {
    this.tmpCanvasEl = window.fabric.util.createCanvasElement();
    this.tmpCanvasEl.width = this.spriteWidth || this.width;
    this.tmpCanvasEl.height = this.spriteHeight || this.height;
  },

  createSpriteImages: function() {
    this.spriteImages = [ ];

    var steps = this._element.width / this.spriteWidth;
    for (var i = 0; i < steps; i++) {
      this.createSpriteImage(i);
    }
  },

  createSpriteImage: function(i) {
    var tmpCtx = this.tmpCanvasEl.getContext('2d');
    tmpCtx.clearRect(0, 0, this.tmpCanvasEl.width, this.tmpCanvasEl.height);
    tmpCtx.drawImage(this._element, -i * this.spriteWidth, 0);

    var dataURL = this.tmpCanvasEl.toDataURL('image/png');
    var tmpImg = window.fabric.util.createImage();

    tmpImg.src = dataURL;

    this.spriteImages.push(tmpImg);
  },

  _render: function(ctx) {
    ctx.drawImage(
      this.spriteImages[this.spriteIndex],
      -this.width / 2,
      -this.height / 2
    );
  },

  play: function() {
    var _this = this;
    this.animInterval = setInterval(function() {

      _this.onPlay && _this.onPlay();
      _this.dirty = true;
      _this.spriteIndex++;
      if (_this.spriteIndex === _this.spriteImages.length) {
        _this.spriteIndex = 0;
      }
    }, this.frameTime);
  },

  stop: function() {
    clearInterval(this.animInterval);
  }
});

window.fabric.Sprite.fromURL = function(url, callback, imgOptions) {
  window.fabric.util.loadImage(url, function(img) {
    callback(new window.fabric.Sprite(img, imgOptions));
  });
};

window.fabric.Sprite.async = true;