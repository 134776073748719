import logo from './logo.svg';
import './App.css';
import GifMaker from "./components/GifMaker"
import Header from "./components/Header"
import Footer from "./components/Footer"

function App() {
  return (
    <div className="App">
      <Header />
      <GifMaker />
      <Footer />
    </div>
  );
}

export default App;
