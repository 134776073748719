import React, { useRef, useState, useEffect } from "react";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import RecordRTC from "recordrtc";
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
import { saveAs } from "file-saver";
import { fabricGif } from "./fabricGif";
import "./style.css"; 
import "./sprite.class.js";

const logoHumpMakerUrl = require("../../imgs/logoHumpMaker.png");

export default function GifMaker() {
  const { editor, onReady } = useFabricJSEditor();
  const fileInputRef = useRef(null);
  const [textColor, setTextColor] = useState("#5C4F7B");
  const [brushSize, setBrushSize] = useState(5);
  const [brushShape, setBrushShape] = useState("round");
  const [isDrawingMode, setIsDrawingMode] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [videoPreview, setVideoPreview] = useState(null);
  const [isDownloadEnabled, setIsDownloadEnabled] = useState(false);
  const ffmpeg = createFFmpeg({ log: true, waitForLoad: true });
  const [counter, setCounter] = useState(1);
  const [selectedGif, setSelectedGif] = useState(null);
  const [loadedGifs, setLoadedGifs] = useState([]);

  useEffect(() => {
    if (editor) {
      setCanvasBackgroundImage();
      editor.canvas.freeDrawingBrush.width = brushSize;
      editor.canvas.freeDrawingBrush.strokeWidth = brushSize;
      editor.canvas.freeDrawingBrush.strokeLineCap = brushShape;
      editor.canvas.isDrawingMode = isDrawingMode;
      editor.canvas.freeDrawingBrush.color = textColor;
      return () => {
        editor.canvas.isDrawingMode = false;
      };
    }
  }, [editor, brushSize, brushShape, isDrawingMode]);

  const convertToGif = async () => {
    if (videoPreview) {
      setIsDownloadEnabled(false);
      await ffmpeg.load();
      const name = "recorded.webm";
      const video = await fetchFile(videoPreview);
      const gifName = `HumpMaker-Hump_WTF-StrayDogz_WTF_${counter}.gif`;
      ffmpeg.FS("writeFile", name, video);
      await ffmpeg.run(
        "-i",
        name,
        /* "-t",
        "2.5",
        "-ss",
        "2.0",*/ 
        "-r",
        `10`,
        "-pix_fmt",
        "bgr8",
        "-vf",
        "fps=10,scale=-1:500",
        "-preset",
        "slow",
        "-f",
        "gif",
        gifName
      );
      const data = ffmpeg.FS("readFile", gifName);
      const url = URL.createObjectURL(new Blob([data.buffer], { type: "image/gif" }));

      saveAs(url, gifName);
      setCounter(counter + 1);
      setIsDownloadEnabled(true);
    }
  };
  const handleToggleDrawingMode = () => {
    setIsDrawingMode(!isDrawingMode);
  };
  const handleBrushSizeChange = (event) => {
    setBrushSize(parseInt(event.target.value));
  };
  const deactivateAll = () => {
    editor.canvas.discardActiveObject().renderAll();
  }
  const handleBrushShapeChange = (event) => {
    setBrushShape(event.target.value);
  };
  const handleColorChange = (event) => {
    setTextColor(event.target.value);
  };
  const onImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const imgElement = document.createElement("img");
      imgElement.onload = () => {
        const humperImg = new window.fabric.Image(imgElement, {
          hasControls: true,
          padding: 0,
          cornerSize: 5,
          centeredScaling: true,
          centeredRotation: true,
          borderColor: "transparent",
          cornerColor: "transparent",
          cornerStrokeColor: "purple",
        });
        editor.canvas.add(humperImg);
        const scale = Math.min(
          editor.canvas.width / humperImg.width,
          editor.canvas.height / humperImg.height
        );
        humperImg.scale(scale);
        editor.canvas.renderAll();
      };
      imgElement.src = reader.result;
    };
    reader.readAsDataURL(file);
    document.getElementById("inputImg").value = "";
  };
  const onAddText = () => {
    const text = new window.fabric.IText("Hump it!", {
      left: 50,
      top: 50,
      fill: textColor,
      fontFamily: "Headings",
      fontWeight: 700,
      hasControls: true,
      padding: 0,
      cornerSize: 5,
      centeredScaling: true,
      centeredRotation: true,
      borderColor: "transparent",
      cornerColor: "transparent",
      cornerStrokeColor: "purple",
    });
    editor.canvas.add(text);
    editor.canvas.bringToFront(text);
    editor.canvas.renderAll();
  };
  const onAddHumper = () => {
    window.fabric.Sprite.fromURL(
      require("../../imgs/leghump_spritesheet.png"),
      createSprite()
    );
    function createSprite() {
      return function (sprite) {
        sprite.set({
          left: 0,
          top: 0,
          hasControls: true,
          padding: 0,
          cornerSize: 5,
          centeredScaling: true,
          centeredRotation: true,
          borderColor: "transparent",
          cornerColor: "transparent",
          cornerStrokeColor: "purple",
        });
        editor.canvas.add(sprite);
        editor.canvas.bringToFront(sprite);
        sprite.set("dirty", true);
        sprite.play();
      };
    }
    (function render() {
      editor.canvas.renderAll();
      window.fabric.util.requestAnimFrame(render);
    })();
  };
  const onDeleteSelected = () => {
    editor.deleteSelected();
  };
  const setCanvasBackgroundImage = () => {
    if (editor.canvas) {
      window.fabric.Image.fromURL(
        require("../../imgs/background-image.jpg"),
        function (backgroundImage) {
          editor.canvas.setBackgroundImage(
            backgroundImage,
            editor.canvas.renderAll.bind(editor.canvas),
            {
              scaleX: editor.canvas.width / backgroundImage.width,
              scaleY: editor.canvas.height / backgroundImage.height,
            }
          );
        }
      );
    }
  };
  const onAddLogo = () => {
    window.fabric.Image.fromURL(require("../../imgs/logo_small.png"), function (logoHump) {
      logoHump.set({
      left: 395,
      top: 450,
      selectable: false,
    });
    editor?.canvas.add(logoHump);
    editor?.canvas.bringToFront(logoHump);
    });
  };
  const handleRecordButtonClick = async () => {
    if (!isRecording) {
      await startRecording();
      onAddLogo();
    } else {
      stopRecording();
    }
  };
  const startRecording = async () => {
    if (editor.canvas) {
      editor.canvas.renderAll();
      const canvasElement = editor.canvas.getElement();
      try {
        const stream = await canvasElement.captureStream();
        const options = {
          type: "video",
          mimeType: "video/webm",
          frameRate:10
        };
        const newRecorder = new RecordRTC(stream, options);
        newRecorder.startRecording();
        setRecorder(newRecorder);
        setIsRecording(true);
      } catch (error) {
        console.error("Error capturing stream:", error);
      }
    }
  };
  const stopRecording = () => {
    if (recorder) {
      recorder.stopRecording(() => {
        const blob = recorder.getBlob();
        const url = URL.createObjectURL(blob);
        setVideoPreview(url);
        setIsDownloadEnabled(true);
        setRecorder(null);
        setIsRecording(false);
      });
    }
  };
  const handleGifUpload = (event) => {
    const file = event.target.files[0];
    importGif(file);
    document.getElementById("inputGif").value = "";
  };
  const importGif = async (file) => {
    const selectedGifUrl = URL.createObjectURL(file);
    setSelectedGif(selectedGifUrl);

    if (editor && editor.canvas && !loadedGifs.includes(selectedGifUrl)) {
      const gif1 = await fabricGif(selectedGifUrl, 200, 200);
      gif1.set({ top: 50, left: 50 });
      editor.canvas.add(gif1);

      window.fabric.util.requestAnimFrame(function render() {
        editor.canvas.renderAll();
        window.fabric.util.requestAnimFrame(render);
      });

      setLoadedGifs((prevGifs) => [...prevGifs, selectedGifUrl]);
    }
  };


  return (
    <section id="gifMaker">
      <div className="GifMaker">
        <div className="logoHumpMaker">
          <img src={logoHumpMakerUrl} alt="Logo GIF Hump Maker" />
        </div>
        <div className="canvasAndEditorContainer">
          <div className="canvasEditor">
            <div className="firstBlock">
              <div id="deleteDeSelected">
                <label>
                  Show all layers
                  <button className="deselectObject" onClick={deactivateAll}>Deselect</button>
                </label>
                <label>
                  Delete selected
                  <button className="deleteSelectedEditor" onClick={onDeleteSelected}></button>
                </label>
              </div>
              <div className="firstLineTools">
                <label className="addPicture">
                  <input id="inputImg" type="file" accept="image/*" ref={fileInputRef} onChange={onImageUpload}/>
                  Add Picture
                </label>
                <label className="addGif">
                  Add Gif
                  <input id="inputGif" type="file" accept=".gif" onChange={handleGifUpload} />
                </label>
                <button className="addHumperEditor" onClick={onAddHumper}>Add humper</button>
              </div>
              <div className="secondLineTools">
                <button className="addTextEditor" onClick={onAddText}>Add text</button>
                <label className="textBrushColor">Text/Brush Color:
                <input
                  type="color"
                  value={textColor}
                  onChange={handleColorChange}
                />
                </label>
              </div>
              <div className="DrawingTools">
                <div className="ToggleDrawing">
                  <label>
                    {isDrawingMode ? "Disable Drawing Mode" : "Enable Drawing Mode"}
                  <button className={isDrawingMode ? "Disabled" : "Enabled"} onClick={handleToggleDrawingMode}>
                  </button>
                  </label>
                </div>
                <div className={isDrawingMode ? "Disable" : "Enable"}>
                  <div className="moreDrawingTools">
                    <div className="brushSize">
                      <label>Brush Size:</label>
                      <div className="slidecontainer">
                        <input
                          type="range"
                          min="1"
                          max="20"
                          value={brushSize}
                          onChange={handleBrushSizeChange}
                          className="slider"
                          id="rangeSlide"
                        />
                      </div>
                    </div>
                    <div className="brushShape">
                      <label>Brush Shape:</label>
                      <div className="brushShapeList">
                        <span className="selectBrush">
                          <select value={brushShape} onChange={handleBrushShapeChange}>
                            <option value="round">Round</option>
                            <option value="square">Square</option>
                          </select>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="recordingTools">
              <button className="startRecordingEditor" onClick={handleRecordButtonClick}>
                {isRecording ? "Stop Recording" : "Start Recording"}
              </button>
              {videoPreview && (
                <div>
                  <video src={videoPreview} controls autoPlay muted />
                </div>
              )}
              {isDownloadEnabled && (
                <div>
                  <button className="stopRecordingEditor" onClick={convertToGif}>Download the GIF</button>
                </div>
              )}
            </div>
          </div>
          <FabricJSCanvas className="sample-canvas" onReady={onReady} />
        </div>
      </div>
    </section>
  );
}
