import React from "react";
import './header.css';

const Header = () => {

  /*MENU MOBILE*/
  const [show, setShow] = React.useState();
  /*MENU MOBILE END*/

  return (
    <>
      <header>
        <nav className="menuDesktop">
          <h1><a href="https://hump.wtf/" rel="noopener noreferrer"><img src={require('../../imgs/token_img.png')} alt="$HUMP StrayDogz Meme Token"></img></a></h1>
          <ul>
            <li>
              <a className="uniswap" href="https://app.uniswap.org/#/tokens/ethereum/0xa44fb2638f53d7d7caa8c67add1ea98b60783c0a"target="_blank" rel="noopener noreferrer">Buy on Uniswap</a>
            </li>
            <li>
              <a className="humpBoard" href="https://hump.wtf/#/claim/">Hump Board</a>
            </li>
            <li>
              <a className="strayScan" href="https://straydogz.wtf/stray-scan" target="_blank" rel="noopener noreferrer">Stray Scan</a>
            </li>
            <li>
              <a className="sdOpensea" href="https://opensea.io/Straydogz_wtf/created" target="_blank" rel="noopener noreferrer">StrayDogz NFTs</a>
            </li>
          </ul>
        </nav>
        <div className={`mobileMenu ${show ? "show" : ""}`}>
          <h1><a href="https://hump.wtf/" rel="noopener noreferrer"><img src={require('../../imgs/token_img.png')} alt="logo $HUMP StrayDogz Meme Token"></img></a></h1>
          <div className="toggleContainer">
            <button className="toggleMobile" onClick={() => setShow(!show)}>
            </button>
          </div>
          <nav className={`nav`}>
            <ul onClick={() => setShow(!show)}>
              <li>
                <a className="uniswap" href="https://app.uniswap.org/#/tokens/ethereum/0xa44fb2638f53d7d7caa8c67add1ea98b60783c0a" target="_blank" onClick={() => setShow(!show)} rel="noopener noreferrer">Buy on Uniswap</a>
              </li>
              <li>
                <a className="humpBoard" href="https://hump.wtf/#/claim/" onClick={() => setShow(!show)} rel="noopener noreferrer">Hump Board</a>
              </li>
              <li>
                <a className="strayScan" href="https://straydogz.wtf/stray-scan" target="_blank" onClick={() => setShow(!show)} rel="noopener noreferrer">Stray Scan</a>
              </li>
              <li>
                <a className="sdOpensea" href="https://opensea.io/Straydogz_wtf/created" target="_blank" onClick={() => setShow(!show)} rel="noopener noreferrer">StrayDogz NFTs</a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="social">
          <a href="https://twitter.com/HumpCoin" target="_blank" rel="noopener noreferrer"><i className="icon twitter"></i></a>
          <a href="https://t.me/+FydH0u8S7hliMDE0" target="_blank" rel="noopener noreferrer"><i className="icon telegram"></i></a>
          <a href="https://discord.gg/MbkMkedqaB" target="_blank" rel="noopener noreferrer"><i className="icon discord"></i></a>
          <a href="https://opensea.io/Straydogz_wtf/created" target="_blank" rel="noopener noreferrer"><i className="icon opensea"></i></a>
        </div>
      </header>
    </>
  );
};

export default Header;